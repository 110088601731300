import axios from 'axios';

export class AuthService {
  login = (username, password, client) => {
    return axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/token?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&client=${client}`, {
    }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  signUp = (user) => {
    // Codigo para probar componente para crear usuario
    // return new Promise((res, rej) => {
    //   setTimeout(() => {
    //     res("ok")
    //   }, 500);
    // })
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/public/activate-user`,
      {
        id: user.id,
        email: user.email.toLowerCase(),
        password: user.password,
        completeName: user.completeName
      },
      {
        headers: {
          'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
        }
      }
    )
  }

  sendEmailPassword = (email, clientId) => {
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/public/send-email-restore-password?client=${clientId}`,
      {
        email: email.email
      }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  restorePassword = (uuid, password) => {
    return axios.put(`${process.env.REACT_APP_API_BASE_URL}/public/restore-password/${uuid}`,
      {
        password: password
      }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }

  refreshToken = async (token) => {
    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/oauth/token?grant_type=token&refresh_token=${token}`, {
    }, {
      headers: {
        'Authorization': `Basic ${process.env.REACT_APP_TOKEN_BASIC_AUTH}`
      }
    })
  }
}
