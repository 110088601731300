import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, AppBar, Toolbar, Typography, Button, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import TextField from '@material-ui/core/TextField';
import { isMobileOnly } from 'react-device-detect';
import GetAppIcon from '@material-ui/icons/GetApp';
import './text';
import '@react-pdf-viewer/core/lib/styles/index.css';
import './document-pdf-viewer.scss';
import { calculateZoom } from '../../../core/helpers/zoomValues';
import { tokenExpired } from '../../../core/utils';
import { authActions } from '../../../core/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentHistory } from '../../../core/http/functionRequests';
import { getCurrentUser } from '../../../core/helpers';


export const DocumentPdfViewer = (props) => {
  const {
    id,
    url,
    title,
    open,
    review,
    onReview,
    sign,
    onSign,
    pages = 1,
  } = props;

  const refFrame = useRef(null)
  const [zoom, setZoom] = useState(60)
  const [zoomText, setZoomText] = useState('60%')
  const [page, setPage] = useState(1);
  const [pageText, setPageText] = useState(1);
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();


  const handleClose = () => {
    props.onClose();
  }

  const setAllZoom = (n) => {
    setZoom(n)
    setZoomText(`${n}%`)
  }

  const handleZoomInput = (e) => {
    setZoomText(e.target.value)
  }

  const handlePageInput = (e) => {
    const value = e.target.value
    if (value === "") {
      setPageText(value)
      return
    }
    const regExp = new RegExp(/^\d+$/)
    if (regExp.test(value)) {
      setPageText(value)
    }
  }

  const handleBlur = () => {
    const regExp = new RegExp(/^\d+$/)
    if (regExp.test(zoomText)) {
      if (zoomText < 25) {
        setZoom(25)
        setZoomText('25%')
        return
      }
      if (zoomText > 500) {
        setZoom(500)
        setZoomText('500%')
        return
      }
      setZoom(zoomText)
      setZoomText(prev => `${prev}%`)
    } else {
      setZoomText(`${zoom}%`)
    }
  }

  const handleBlurPage = () => {
    const regExp = new RegExp(/^\d+$/)
    if (regExp.test(pageText)) {
      // if (pageText === page) {
      //   refFrame.current.contentWindow.location.reload(true)
      //   return
      // }
      if (pageText <= pages && pageText >= 1) {
        setPage(pageText)
        return
      }
    }
    setPageText(page)

  }

  const handleZoom = (type) => {
    setAllZoom(calculateZoom(zoom, type))
  }

  const handleDownload = async (e) => {
    e.preventDefault()
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    try {
      const { userId } = getCurrentUser()
      const body = {
        documentId: id,
        action: "descargó el",
        userId
      }
      await setDocumentHistory(body)
      const tempLink = document.createElement('a')
      tempLink.href = url;
      tempLink.setAttribute('download', title)
      tempLink.click()
    } catch (error) {
      console.error(error);
      return

    }
  }

  const handleKeyZoom = (e) => {
    const key = e.key;
    if (key === "Enter") {
      handleBlur()
    }
  }

  const handleKeyPage = (e) => {
    const key = e.key;
    if (key === "Enter") {
      handleBlurPage()
    }
  }

  useEffect(() => {
    if (!refFrame.current) return
    refFrame.current.contentWindow.location.reload(true)
  }, [zoom, page])


  return (
    <Dialog
      fullScreen
      maxWidth={'xl'}
      open={open}
    >
      <AppBar position="static">
        <Toolbar style={{ minHeight: '48px' }}>
          <Grid container spacing={3}>

            <Grid item container xs={12} sm justifyContent='flex-start' alignItems='center' spacing={3}>

              <Grid item >
                <IconButton onClick={() => { handleClose() }} aria-label="close">
                  <ArrowBackIcon className="color-button" />
                </IconButton>
              </Grid>

              <Grid item >
                <Typography variant="subtitle1" >
                  {isMobileOnly ? `${title.truncate(30)}` : title}
                </Typography>
              </Grid>

            </Grid>

            <Grid item container xs={12} sm justifyContent='center' spacing={3}>

              <Grid item container justifyContent='center' alignItems='center' spacing={1}>
                <Grid item>
                  <IconButton
                    disabled={zoom <= 25}
                    onClick={() => handleZoom("out")}
                    style={{ width: "10px", height: "10px" }}
                  >
                    <ZoomOutIcon style={{ color: 'white' }} />
                  </IconButton>
                </Grid>

                <Grid item>
                  <TextField
                    className='zoom-text'
                    variant='outlined'
                    value={zoomText}
                    size='small'
                    fullWidth
                    margin='dense'
                    onChange={handleZoomInput}
                    onBlur={handleBlur}
                    onKeyDownCapture={handleKeyZoom}
                  />
                </Grid>

                <Grid item>
                  <IconButton
                    disabled={zoom >= 500}
                    onClick={() => handleZoom("in")}
                    style={{ width: "10px", height: "10px" }}
                  >
                    <ZoomInIcon style={{ color: 'white' }} />
                  </IconButton>
                </Grid>

                <Grid item>
                  <span>en la pág.</span>
                </Grid>

                <Grid item>
                  <TextField
                    className='page-text'
                    variant='outlined'
                    value={pageText}
                    size='small'
                    fullWidth
                    margin='dense'
                    onChange={handlePageInput}
                    onBlur={handleBlurPage}
                    onKeyDownCapture={handleKeyPage}
                  />
                </Grid>
                <Grid item>
                  <span>/</span>
                </Grid>
                <Grid>
                  <span style={{ fontSize: "13px" }}>{pages}</span>
                </Grid>
              </Grid>

            </Grid>

            <Grid item container xs={12} sm justifyContent="flex-end" alignItems="center">

              <Grid item>
                <IconButton style={{ color: 'white' }} onClick={handleDownload}>
                  <GetAppIcon />
                </IconButton>
              </Grid>

              {review &&
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onReview()}
                    style={{ marginLeft: 'auto' }}
                  >
                    Revisar
                  </Button>
                </Grid>
              }

              {sign &&
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onSign()}
                    style={{ marginLeft: review ? '.5rem' : 'auto' }}
                  >
                    Firmar
                  </Button>
                </Grid>
              }

            </Grid>

          </Grid>
        </Toolbar>
      </AppBar>

      <DialogContent className="container-viewer">
        <iframe
          ref={refFrame}
          id='pdf-iframe-document'
          title='nombre documento'
          src={`${url}#toolbar=0&navpanes=0&scrollbar=0&zoom=${zoom}&page=${page}`}
          height={'100%'}
          width={'100%'}
        />
      </DialogContent>
    </Dialog>
  );
}

DocumentPdfViewer.propTypes = {
  url: PropTypes.string,
  base64: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.any,
  review: PropTypes.bool,
  onReview: PropTypes.func,
  sign: PropTypes.bool,
  onSign: PropTypes.func,
};
