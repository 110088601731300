import React, { createRef, useEffect, useState } from "react";
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { AppBar, Box, Grid, Button, Drawer, alpha, IconButton, makeStyles, Toolbar, Typography, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';
import './PDFViewer.css';
import { useRenderCanvas } from "../../../hooks/useRenderCanvas";
import SignerCard from "./SignerCard";
import PDFViewCanvas from "./PDFviewCanvas";


const drawerWidth = '18rem';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  title: {
    marginLeft: '1rem'
  },
  buttonAddUser: {
    borderRadius: '9px',
    width: '250px',
    backgroundColor: '#1BAAFB',
    '&:hover': {
      backgroundColor: '#1BAAFB'
    }
  },
  navigate: {
    flex: '1',
    marginLeft: '11rem',
    textAlign: 'center'
  },
  navigatePage: {
    textAlign: 'right'
  },
  buttonNavigatePage: {
    borderRadius: '6px'
  },
  buttonDraw: {
    borderRadius: '9px',
    padding: '1px 15px',
    width: '180px',
    height: '20px',
    backgroundColor: '#1BAAFB',
    '&:hover': {
      backgroundColor: '#1BAAFB'
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    boxSizing: 'border-box'
  },
  stickerCanvas: {
    top: '1',
    bottom: '1',
    left: drawerWidth,
    right: 0,
    margin: 'auto',
    position: 'absolute',
    zIndex: 1
  }
}));


export default function PDFViewer({ url, users, documentName, onClose, onSaveSigns, signatures = [] }) {
  const classes = useStyles();

  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [signers, setSigners] = useState(users ?? []);
  const [isPositioningSticker, setIsPositioningSticker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [offsetX, setOffsetX] = useState();
  const [offsetY, setOffsetY] = useState();
  const [startX, setStartX] = useState();
  const [startY, setStartY] = useState();
  const [dragSignatures, setDragSignatures] = useState(signatures ?? []);
  const [selectedText, setSelectedText] = useState(null);
  const { canvasRef, setCanvasSize, pdfRef, canvasWidth, canvasHeight, viewport, scale } = useRenderCanvas(url);
  const [inputValue, setInputValue] = useState(1);
  const [changeSize, setChangeSize] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [count, setCount] = useState(0);
  const [frame, setFrame] = useState({ w: 160, h: 50 });

  const titleBackgroundH = 14;
  const minLimits = { square: { w: 25, h: 25 }, titleBackgroundH: 14 };

  const renderStickerPage = (pdfRef) => {
    const stickerContainer = canvasRef.current;
    const canvasOffset = stickerContainer?.getBoundingClientRect();
    setOffsetX(canvasOffset.left);
    setOffsetY(74); // It should be canvasOffset.top, but it is making some trouble.
    setTotalPages(pdfRef.numPages);
  };

  const handleNextPage = () => {
    if (currentPage >= pdfRef.numPages) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage <= 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  const pageByInputRequest = () => {
    if (inputValue < 1 || inputValue > pdfRef.numPages) {
      swal('Aviso', 'El numéro no es una opción valida de página', 'warning');
    } else {
      const pages = inputValue;
      setCurrentPage(pages);
    }
  };

  const onChangeHandler = (event) => {
    setInputValue(parseInt(event.target.value));
  };

  const drawDeleteSticker = (ctx, fromX, fromY, width) => {
    const centerX = fromX + Math.round((width) / 2);
    const centerY = fromY + Math.round((width) / 2);
    const radius = Math.round(width / 2);

    ctx.setLineDash([]);
    ctx.beginPath();
    ctx.strokeStyle = 'black';
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(fromX + width, fromY + width);
    ctx.moveTo(fromX + width, fromY);
    ctx.lineTo(fromX, fromY + width);
    ctx.stroke();

    ctx.beginPath();
    // ctx.arc(centerX, centerY, radius + 2, 0, 2 * Math.PI);
    ctx.stroke();
  }

  const drawCornerArrow = (ctx, fromX, fromY, length) => {
    const toY = fromY - length;
    const toX = fromX + length;
    const headlen = 5; // Personaliza la longitud de la línea de flecha
    const theta = 45; // Personaliza el ángulo entre la línea de flecha y la línea recta, creo que 45 ° es lo correcto
    let arrowX = 0;
    let arrowY = 0; // Las coordenadas del punto final de la línea de flecha
    // Calcula cada ángulo y las coordenadas del punto final de la flecha correspondiente
    const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI;
    const angle1 = ((angle + theta) * Math.PI) / 180;
    const angle2 = ((angle - theta) * Math.PI) / 180;
    const topX = headlen * Math.cos(angle1);
    const topY = headlen * Math.sin(angle1);
    const botX = headlen * Math.cos(angle2);
    const botY = headlen * Math.sin(angle2);
    ctx.beginPath();
    // Dibuja una línea recta
    ctx.moveTo(fromX + 2, fromY - 2);
    ctx.lineTo(toX, toY);

    arrowX = toX + topX;
    arrowY = toY + topY;
    // Dibuja la línea de flecha inferior
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(toX, toY);

    arrowX = toX + botX;
    arrowY = toY + botY;
    // Dibuja la línea de flecha superior
    ctx.lineTo(arrowX, arrowY);

    ctx.setLineDash([]);
    ctx.strokeStyle = 'black';
    ctx.stroke();
  }

  const drawInferiorArrow = (ctx, fromX, fromY, length) => {
    const toY = fromY + length;
    const toX = fromX - length;
    const headlen = 5; // Personaliza la longitud de la línea de flecha
    const theta = 45; // Personaliza el ángulo entre la línea de flecha y la línea recta, creo que 45 ° es lo correcto
    let arrowX = 0;
    let arrowY = 0; // Las coordenadas del punto final de la línea de flecha
    // Calcula cada ángulo y las coordenadas del punto final de la flecha correspondiente
    const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI;
    const angle1 = ((angle + theta) * Math.PI) / 180;
    const angle2 = ((angle - theta) * Math.PI) / 180;
    const topX = headlen * Math.cos(angle1);
    const topY = headlen * Math.sin(angle1);
    const botX = headlen * Math.cos(angle2);
    const botY = headlen * Math.sin(angle2);
    ctx.beginPath();
    // Dibuja una línea recta
    ctx.moveTo(fromX, fromY);

    arrowX = toX + topX;
    arrowY = toY + topY;
    // Dibuja la línea de flecha inferior
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(toX, toY);

    arrowX = toX + botX;
    arrowY = toY + botY;
    // Dibuja la línea de flecha superior
    ctx.lineTo(arrowX, arrowY);

    ctx.setLineDash([]);
    ctx.strokeStyle = 'black';
    ctx.stroke();
  }

  const drawLineArrow = (ctx, fromX, fromY, toX, toY, color) => {
    const headlen = 8; // Personaliza la longitud de la línea de flecha
    const theta = 45; // Personaliza el ángulo entre la línea de flecha y la línea recta, creo que 45 ° es lo correcto
    let arrowX = 0;
    let arrowY = 0; // Las coordenadas del punto final de la línea de flecha
    // Calcula cada ángulo y las coordenadas del punto final de la flecha correspondiente
    const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI;
    const angle1 = ((angle + theta) * Math.PI) / 180;
    const angle2 = ((angle - theta) * Math.PI) / 180;
    const topX = headlen * Math.cos(angle1);
    const topY = headlen * Math.sin(angle1);
    const botX = headlen * Math.cos(angle2);
    const botY = headlen * Math.sin(angle2);
    ctx.beginPath();
    // Dibuja una línea recta
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.lineWidth = 2;
    ctx.setLineDash([]);
    arrowX = toX + topX;
    arrowY = toY + topY;
    // Dibuja la línea de flecha superior
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(toX, toY);

    arrowX = toX + botX;
    arrowY = toY + botY;
    // Dibuja la línea de flecha inferior
    ctx.lineTo(arrowX, arrowY);

    ctx.setLineDash([]);
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.lineWidth = 1;
  }


  function draw() {
    const canvasEl = canvasRef.current;
    const ctxs = canvasEl.getContext('2d');
    ctxs.clearRect(0, 0, canvasEl.width, canvasEl.height);

    dragSignatures.forEach(text => {
      if (text.page !== currentPage) return

      ctxs.setLineDash([2, 3]);
      ctxs.strokeStyle = 'red';
      ctxs.strokeRect(text.x, text.y, text.squareW, text.squareH);


      ctxs.fillStyle = 'yellow';
      ctxs.fillRect(text.x, text.y, text.squareW, text.titleH);


      ctxs.fillStyle = 'red';
      ctxs.font = '7px verdana';
      ctxs.fillText('Arrastrar y soltar', text.x, text.y + 10);

      drawLineArrow(ctxs, text.x + 20, text.y + text.squareH - 20, text.x + 3, text.y + text.squareH - 3, 'red');
      drawDeleteSticker(ctxs, text.x + text.squareW - 30, text.y + 2, 11);
      drawCornerArrow(ctxs, text.x + text.squareW - 14, text.y + 14, 12);
      drawInferiorArrow(ctxs, text.x + text.squareW, text.y, 12);

      ctxs.fillStyle = 'black';
      ctxs.font = '8px verdana';
      ctxs.fillText(text.text, text.x + 25, text.y + text.squareH - 5);

    });

    if (showDeleteMessage) {
      ctxs.strokeStyle = 'black';
      ctxs.strokeRect(startX - 10, startY - 25, 45, 15);
      ctxs.fillStyle = "white";
      ctxs.fillRect(startX - 10, startY - 25, 45, 15);

      ctxs.fillStyle = "black";
      ctxs.font = '10px verdana';
      ctxs.fillText("Eliminar", startX - 8, startY - 15)
    }
  }


  const checkInDeleteSticker = (x, y, text) => {
    if (text.page !== currentPage) return
    const initPointX = text.x + text.squareW - 30;
    const initPointY = text.y + 1;
    const posX = x >= initPointX && x <= initPointX + 9;
    const posY = y >= initPointY && y <= initPointY + 9;
    return posX && posY;
  }

  const checkInRightBorder = (x, y, text) => {
    const initPointX = text.x + text.squareW;
    const initPointY = text.y;
    const posX = x >= initPointX - 14 && x <= initPointX;
    const posY = y >= initPointY && y <= initPointY + 14;
    return posX && posY;
  }

  function textHittest(x, y, textIndex) {
    const text = dragSignatures[textIndex];
    if (text.page !== currentPage) return
    const posX = x >= text.x && x <= text.x + text.squareW;
    const posY = y >= text.y && y <= text.y + text.squareH;
    return posX && posY;
  }

  const validateStickersGenerated = (userId) => (dragSignatures.filter((item) => item.userId === userId).length > 0);

  function handleMouseDown(e) {
    console.log(`estoy en (${startX},${startY})`);

    const tempStartX = Math.round(e.clientX - offsetX);
    const tempStartY = Math.round(e.clientY - offsetY);

    setStartX(tempStartX);
    setStartY(tempStartY);




    dragSignatures.forEach((text, i) => {
      if (text.page === currentPage) {
        if (checkInDeleteSticker(startX, startY, text)) {
          setDragSignatures(old => old.filter(el => el.id !== text.id));
          setShowDeleteMessage(false);
        } else if (textHittest(startX, startY, i)) {
          setSelectedText(text);
        }
        if (checkInRightBorder(startX, startY, text)) {
          setChangeSize(true);
        }
      }
    });
  }

  function handleMouseUp(e) {
    setSelectedText(null);
    setChangeSize(false);
  }

  function handleMouseOut(e) {
    setSelectedText(null);
    setChangeSize(false);
  }

  /**
   * Function that calculates cursor position in the sticker canvas. If the cursor hovers any
   * sign sticker in the canvas, the cursor type. will change to pointer type, if it is not over any
   * sign sticker, the cursor type will be 'grab'
   * @param {number} cursorX - Current cursor position in x axis.
   * @param {number} cursorY - Current cursor position in y axis.
   */
  const updateCursorPosition = (cursorX, cursorY) => {
    // Gets canvas element
    const canvasD = canvasRef.current;
    // sets cursor's coordinates in the canvas.
    const tempStartX = Math.round(cursorX - offsetX);
    const tempStartY = Math.round(cursorY - offsetY);
    setStartX(tempStartX);
    setStartY(tempStartY);
    // Checks if the cursor is over one of the sign stickers.
    const isCursorInSticker = dragSignatures.reduce((pv, _current, i) => pv || textHittest(startX, startY, i), false);
    const isCursorInDeleteSticker = dragSignatures.reduce((prev, curr) => prev || checkInDeleteSticker(startX, startY, curr), false);
    const isCursorInRightBorder = dragSignatures.reduce((prev, curr) => prev || checkInRightBorder(startX, startY, curr), false);
    // If it is, cursor type will be 'grab', otherwise it will be 'pointer'

    if (!isCursorInDeleteSticker) setShowDeleteMessage(false);

    if (isCursorInRightBorder) canvasD.style.cursor = 'ne-resize'
    else if (isCursorInDeleteSticker) {
      canvasD.style.cursor = 'pointer';
      setShowDeleteMessage(true);
    } else if (isCursorInSticker) canvasD.style.cursor = 'grab';
    else canvasD.style.cursor = 'pointer';
  };

  function handleMouseMove(e) {
    // setMoved(true)
    // Every time the cursor moves inside the canvas,
    updateCursorPosition(e.clientX, e.clientY);
    if (!selectedText) return;

    if (e.clientX < offsetX) return

    e.preventDefault();
    const mouseX = Math.round(e.clientX - offsetX);
    const mouseY = Math.round(e.clientY - offsetY);
    const dx = mouseX - startX;
    const dy = mouseY - startY;

    setDragSignatures(old => old.map(text => {
      if (changeSize && text.id === selectedText.id) {
        const newWidth = text.squareW + dx;
        const newHeight = text.squareH - dy;
        if (newWidth > minLimits.square.w) {
          text.squareW = newWidth;
        }
        if (newHeight > minLimits.square.h) {
          text.squareH = newHeight;
          text.y += dy;
        }

        setFrame({ w: text.squareW, h: text.squareH })


      } else if (text.id === selectedText.id && !changeSize) {
        const newPositionX = text.x + dx;
        const newPositionY = text.y + dy;
        if (newPositionX >= 0 && newPositionX + text.squareW <= text.maxEjeX) {
          text.x = newPositionX;
        }
        if (newPositionY >= 0 && newPositionY + text.squareH <= text.maxEjeY) {
          text.y = newPositionY;
        }
      }

      return text

    }))

    draw()
  }

  const filterItem = (item) => dragSignatures.filter((e) => e.userId === item.userId).length !== 0;

  const sendDocumentFirm = async () => {
    const allSigned = signers.every(filterItem);

    const signatures = dragSignatures.map(signature => {
      const coordX = signature.x - 2;
      const coordY = signature.maxEjeY - signature.y - signature.squareH;
      signature.coordConvert = [coordX, coordY];
      return signature;
    });

    console.log('sendDocumentFirm', signers);
    console.log('drawSignature', signatures);

    // validamos si hay firmantes sin firma
    if (!allSigned) {
      swal('Aviso', 'Hay firmantes con etiquetas pendientes', 'warning');
      return;
    }

    swal({
      title: '¿Estás seguro?',
      text: `Terminar posicionamiento de etiquetas`,
      icon: 'warning',
      buttons: ['Cancelar', 'Aceptar'],
      dangerMode: true
    }).then(async (finish) => {
      if (finish) {
        onSaveSigns(signatures);
        onClose();
      }
    });
  };

  const handleGenerateTag = (name, userId) => {


    setIsPositioningSticker(true)

    const canvasD = canvasRef.current;
    const ctxs = canvasD?.getContext('2d');
    const qtyStickers = (dragSignatures.filter(signature => signature.page === currentPage)).length;
    const y = (qtyStickers % 13 + 1) * 30 + 55;
    const stickerName = '[' + name.split('').slice(0, 22).join('') + '...' + ']';

    const text = {
      id: Date.now(),
      name: name,
      page: currentPage,
      userId: userId,
      text: name.length > 23 ? stickerName : name,
      x: 40,
      y: y,
      maxEjeX: canvasWidth,
      maxEjeY: canvasHeight,
      coordConvert: [],
      scale: scale,
      width: 0,
      height: 24,
      squareH: frame.h,
      squareW: frame.w,
      titleH: titleBackgroundH,
    }
    text.width = ctxs.measureText(text.text).width;
    // text.squareW = text.width + 48;
    setDragSignatures((oldTexts) => [...oldTexts, text]);
    setCount(count + 1);
  }


  const handleClose = () => {
    if (dragSignatures.length !== signatures.length) {
      swal({
        title: '¿Deseas salir sin guardar?',
        icon: 'warning',
        buttons: ['Cancelar', 'Aceptar'],
        dangerMode: true
      }).then(async (response) => {
        if (response) {
          onClose();
        }
      });
      return
    }
    onClose()
  }

  const effect = async () => {
    if (!isPositioningSticker) await setCanvasSize(currentPage, pdfRef);
    if (pdfRef) {
      draw()
      renderStickerPage(pdfRef);
    }
  };

  useEffect(() => {
    effect();
  }, [pdfRef]);

  useEffect(() => {
    draw();
    setSigners(oldSigners => oldSigners.map(el => {
      el.check = validateStickersGenerated(el.userId);
      return el;
    }))
  }, [dragSignatures]);

  useEffect(() => {
    draw();
  }, [currentPage]);

  useEffect(() => {
    draw();
  }, [showDeleteMessage]);




  return (
    <Box display="flex" minHeight="100vh">
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Box width={"25rem"} display="flex" alignItems="center">
            <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
            <Tooltip title={documentName}>
              <Typography variant="subtitle1" className={classes.title}>
                {
                  documentName.length > 40
                    ? documentName.slice(0, 37) + '...'
                    : documentName
                }
              </Typography>
            </Tooltip>
          </Box>
          <div id="navigation_controls" className={classes.navigate}>
            <Button onClick={handlePrevPage} variant="contained" color="secondary">
              Previa
            </Button>{' '}
            &nbsp; &nbsp;
            <span>
              Página: <span>{currentPage}</span> / <span>{totalPages}</span>
            </span>{' '}
            &nbsp; &nbsp;
            <Button onClick={handleNextPage} variant="contained" color="secondary">
              Siguiente
            </Button>
          </div>
          <div className={classes.navigatePage}>
            <input
              type="number"
              className={classes.buttonNavigatePage}
              onChange={onChangeHandler}
              size={totalPages}
              id="inputValue"
              value={inputValue}
              name="inputValue"
              min="1"
              max={totalPages}
            />
            &nbsp; &nbsp;
            <Button onClick={pageByInputRequest} variant="contained" color="secondary">
              Ir a Página
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
          root: classes.drawer
        }}
      >
        <Toolbar />
        <Box display="flex" flexDirection="column" flex={1} justifyContent="space-between" overflow="hidden" margin={1}>
          <Box overflow="auto" marginBottom={2}>
            {signers.length > 0 && signers.map((data, index) => (
              <SignerCard
                key={index}
                name={data.name}
                userId={data.userId}
                userCertificate={data.userCertificate}
                onGenerateTag={handleGenerateTag}
                check={data.check ?? false}
              />
            ))}
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => sendDocumentFirm()}
                disabled={!signers.every(filterItem)}
              >
                Finalizar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>

      <div id="canvas-wrap" style={{ flexGrow: 1, padding: 10 }}>
        <Toolbar />
        {dragSignatures?.map((item, i) => {
          return !!item.canvasRef && item.page === currentPage && <canvas key={i} className={classes.stickerCanvas} ref={item.canvasRef} />;
        })}
        <canvas
          id="canvasDraw"
          className={classes.stickerCanvas}
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseOut={handleMouseOut}
        />
        <PDFViewCanvas currentPage={currentPage} url={url} />
      </div>
    </Box>
  );
}



