import React, { Component } from 'react';
import { Button, Grid, Dialog, Link, Typography } from '@material-ui/core';
import { reduxForm, Field } from 'redux-form';
import RenderTextField from '../../core/redux-form-fields/render-text-field';
import RenderCheckbox from '../../core/redux-form-fields/render-checkbox';
import Visibility from '@material-ui/icons/Visibility';
import TermsAndConditions from './terms-conditions';
import SafetyIndicator from "./safety-indicator";
import Alert from '@material-ui/lab/Alert';
import { isMobile } from 'axeleratum-sgc-frontend-library';
import logoAxeleratum from '../../assets/img/logo_axeleratum.png';

class CreateAccount extends Component {

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      termConditionInvalid: false,
      checked: false,
      password: ''
    }
  }

  submitActions(form, formProps) {
    const { submitActions } = formProps;

    if (this.state.checked) {
      submitActions(form);
      this.setState({ termConditionInvalid: false });
    } else {
      this.setState({ termConditionInvalid: true });
    }
  }

  render() {
    const { handleSubmit, onCancel, errors } = this.props;
    const { dialogOpen, checked, password, termConditionInvalid } = this.state;

    return (
      <form onSubmit={handleSubmit((form, metaData, formProps) => this.submitActions(form, formProps))}>
        <Dialog open={dialogOpen}>
          <TermsAndConditions
            className="terms-and-condition-size"
            onReturn={() => this.setState({ dialogOpen: !dialogOpen })}
          />

          <Grid
            container
            justifyContent="flex-end"
            className="terms-and-condition-buttons-container"
          >
            <Button
              className="terms-and-condition-buttons-space"
              variant="contained"
              color="primary"
              onClick={() => this.setState({ checked: true, dialogOpen: false })}
            >
              Aceptar
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.setState({ checked: false, dialogOpen: false })}
            >
              Rechazar
            </Button>
          </Grid>
        </Dialog>

        <Grid
          className={`${isMobile ? 'p-5' : 'pb-3'} animate__animated animate__backInUp`}
          container
          spacing={1}
        >
          <Grid item md={12} xs={12}>
            <h3>Crear Cuenta</h3>
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Nombre completo"
              name="completeName"
              required
              component={RenderTextField}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Correo"
              name="email"
              required
              component={RenderTextField}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Contraseña"
              name="password"
              type="password"
              required
              passwordAction
              onKeyUp={keyUp => this.setState({ password: keyUp })}
              icon={
                <Visibility />
              }
              component={RenderTextField}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              label="Confirmar contraseña"
              name="passwordConfirm"
              type="password"
              required
              passwordAction
              icon={
                <Visibility />
              }
              component={RenderTextField}
            />

            <SafetyIndicator className="mt-3" password={password} />
          </Grid>

          <Grid item md={12} xs={12}>
            <Field
              name="termCondition"
              component={RenderCheckbox}
              checked={checked}
              onChange={(value) => this.setState({ checked: value })}
              label={
                <small className="validated-text">
                  Acepto los &nbsp;
                </small>
              }
            />

            <small className="validated-text">
              <Link
                className="pointer terms-text"
                onClick={() => this.setState({ dialogOpen: !dialogOpen })}
              >
                términos y condiciones
              </Link>
            </small>
          </Grid>

          {
            termConditionInvalid &&
            <Grid item md={12} xs={12}>
              <Alert variant="filled" severity="error">
                Si no acepta los términos y condiciones no se puede concluir el registro de usuario.
              </Alert>
            </Grid>
          }

          {
            errors.message &&
            <Grid item md={12} xs={12}>
              <Alert variant="filled" severity={errors.type}>
                {errors.message}
              </Alert>
            </Grid>
          }

          <Grid item md={12} xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth={true}
            >
              Registrame
            </Button>
          </Grid>

          <Grid className="pt-2" item md={12} xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              onClick={() => onCancel()}
            >
              Ya tengo una cuenta
            </Button>
          </Grid>


        </Grid>
      </form>
    );
  }

}

const validate = (values) => {
  const errors = {
    completeName: '',
    email: '',
    password: '',
    passwordConfirm: ''
  }

  if (!values.completeName) {
    errors.completeName = 'El nombre completo es requerido';
  }

  if (!values.email) {
    errors.email = 'El correo electrónico es requerido';
  }

  if (!values.password) {
    errors.password = 'La contraseña es requerida';
  }

  if (!values.passwordConfirm) {
    errors.passwordConfirm = 'La confirmación de contraseña es requerida';
  }

  if (values.password && values.passwordConfirm) {
    if (values.password !== values.passwordConfirm) {
      errors.passwordConfirm = 'Las contraseñas deben coincidir';
    }
  }

  if (values.completeName) {
    if (values.completeName.length > 50) {
      errors.completeName = 'El nombre completo no puede ser mayor a 50 caracteres';
    }
  }

  if (values.email) {
    if (values.email.length > 50) {
      errors.email = 'El correo no puede ser mayor a 50 caracteres';
    }
  }

  if (values.password) {
    if (values.password.length > 20) {
      errors.password = 'La contraseña no puede ser mayor a 20 caracteres';
    }

    if (values.password.length < 8) {
      errors.password = 'La contraseña no puede ser menor a 8 caracteres';
    }
  }

  return errors;
}

export default reduxForm({
  form: 'CreateAccount',
  validate
})(CreateAccount);
