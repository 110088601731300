import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import './PDFViewer.css';
import { useRenderCanvas } from '../../../hooks/useRenderCanvas';

/** Component that renders  the current page of the opened pdf. */
export default function PDFViewCanvas({ url, currentPage }) {
  const { canvasRef, renderPage, pdfRef } = useRenderCanvas(url);

  useEffect(() => {
    renderPage(currentPage, pdfRef);
  }, [currentPage, pdfRef]);

  return (
    <React.Fragment>
      <Grid
        style={{
          overflow: 'auto',
          textAlign: 'center'
        }}
      >
        <canvas
          id="canvas"
          style={{ top: '', bottom: '0', left: '0', right: '0', margin: 'auto', boxShadow: '0 0 10px rgb(121, 131, 125)' }}
          ref={canvasRef}
        />
      </Grid>
    </React.Fragment>
  );
}
