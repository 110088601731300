import React, { Component } from 'react';
import {
  CardUsers,
  AlertConfirm,
  Alert,
} from 'axeleratum-sgc-frontend-library';
import { Grid, Button, Dialog } from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import AlertInfo from '@material-ui/lab/Alert';
import FormUsers from './form-users';
import { connect } from 'react-redux';
import { alertActions, usersActions } from '../../../core/actions';
import { RolesHttp } from '../../../core/http/roles.http';
import { BusinessHttp } from '../../../core/http/business.http';
import './users.scss';
import {
  validatePermissions,
  permissionScheme,
} from '../../../core/utils/can-i';
import { UserHttp } from '../../../core/http/user.http';

class Users extends Component {
  state = {
    openDialog: false,
    openAddUser: false,
    rolesSelected: null,
    companiesSelected: null,
    userSelected: null,
    openAlertBlock: false,
    openAlertDelete: false,
    registerPlatformUserIsEnabled: false,
    externalUsersManager: false,
    typeForm: 'create',
    usersAvailable: 0,
    openAlertConfirmUser: false,
    formData: {},
    messageAvailableUsers: "",
    openDialogError: false,
    messageError: "",
    openConfirmEditUser: false,
    message: ""
  };

  httpRoles = new RolesHttp();
  businessHttp = new BusinessHttp();
  userhttp = new UserHttp();

  componentDidMount() {
    this.getPermissions();
    this.getData()
  }

  getData() {
    this.props.getTeamData()
  }

  getPermissions() {
    validatePermissions(
      permissionScheme.roleManagement.AssignModifyRolesOfAllUsers
    ).then((enabled) => {
      this.setState({ modifyRolesIsEnabled: enabled });
    });
    validatePermissions(permissionScheme.userManagement.editUser).then(
      (enabled) => {
        this.setState({ editUserIsEnabled: enabled });
      }
    );
    validatePermissions(permissionScheme.userManagement.blockPlatformUser).then(
      (enabled) => {
        this.setState({ blockPlatformUserIsEnabled: enabled });
      }
    );
    validatePermissions(
      permissionScheme.userManagement.reactiveUserAccounts
    ).then((enabled) => {
      this.setState({ reactiveUserAccountsIsEnabled: enabled });
    });
    validatePermissions(
      permissionScheme.userManagement.deletePlatformUsers
    ).then((enabled) => {
      this.setState({ deletePlatformUserIsEnabled: enabled });
    });
    validatePermissions(
      permissionScheme.businessManagement.enrollUsersInCompany
    ).then((enabled) => {
      this.setState({ enrollUsersInCompanyIsEnabled: enabled });
    });

    validatePermissions(permissionScheme.userManagement.registerPlatformUser).then(
      (enabled) => {
        this.setState({ registerPlatformUserIsEnabled: enabled });
      }
    );

    validatePermissions(permissionScheme.userManagement.externalUsersManager).then(
      (enabled) => {
        this.setState({
          externalUsersManager: enabled,
        });
      }
    )
  }

  selectUserOption = (option, user) => {
    switch (option) {
      case 'edit':
        this.setState({
          userSelected: user,
          openDialog: true,
          dialogTitle: 'Editar Usuario',
          typeForm: 'edit',
        });
        break;
      case 'delete':
        this.setState({
          openAlertDelete: true,
          userSelected: user,
        });
        break;

      case 'disable':
        this.setState({
          openAlertBlock: true,
          userSelected: user,
        });
        break;

      default:
        break;
    }
  };

  confirmEditUser = async () => {
    const { editUser } = this.props;
    const { formData } = this.state;
    editUser(formData, this.state.userSelected.id);
    this.setState({ openDialog: false, openAlertConfirmUser: false, openConfirmEditUser: false, message: "" });
  }

  submitSave = async (data) => {
    try {
      const type = this.state.typeForm;
      const { createUser, editUser } = this.props;
      const { formData } = this.state;

      if (type === 'create') {
        createUser(data ?? formData);
        this.setState({ openDialog: false, openAlertConfirmUser: false });
      } else if (type === 'edit') {
        const edited = data ?? formData;
        const userSelected = this.state.userSelected;
        if (edited.email !== userSelected.email || edited.rfc !== userSelected.rfc) {
          const firmActivities = await this.userhttp.userFirmActivities(userSelected.id)
          if (firmActivities > 0) {
            this.setState({ openConfirmEditUser: true, message: "Este usuario tiene actividades pendientes de firma, ¿desea cambiar también los datos en esas actividades y que se le envíe la notificación?" })
          } else {
            editUser(edited, this.state.userSelected.id);
            this.setState({ openDialog: false, openAlertConfirmUser: false });
          }
        } else {
          editUser(edited, this.state.userSelected.id);
          this.setState({ openDialog: false, openAlertConfirmUser: false });
        }
      }
    } catch (error) {
      console.error(error);
    }

  };

  confirmSubmit = (formData) => {
    const { completeName, externalProvider } = formData;
    const { usersAvailable } = this.state;
    const objState = {
      formData,
      openAlertConfirmUser: false,
      messageAvailableUsers: "",
    }

    if (externalProvider) {
      this.submitSave(formData);
      return
    }

    if (usersAvailable > 0) {
      if (!this.state.userSelected || this.state.userSelected?.externalProvider) {
        objState.messageAvailableUsers = `Se dará de alta el usuario ${completeName}, quedando disponibles ${usersAvailable - 1}`;
        objState.openAlertConfirmUser = true;
      } else {
        this.submitSave(formData);
      }
    } else {
      this.submitSave(formData);
    }

    this.setState(objState);
  }

  handleBlockUser = () => {
    const { userSelected } = this.state;

    this.props.blockUser(!userSelected.blocked, userSelected.id);
    this.setState({ openAlertBlock: false, alertStatusBlock: true });
  };

  handleDeleteUser = () => {
    const { userSelected } = this.state;

    this.props.deleteUser(userSelected.id);
    this.setState({ openAlertDelete: false, alertStatusDelete: true });
  };

  handleSaveRoles = (evt, user) => {
    const userRoles = evt.map((role) => ({ id: role }));
    this.props.assignRoles(user.id, userRoles);
  };

  handlePagination = (evt, page) => {
    const { includeEmail, search } = this.props;
    this.props.setPage(page)
    this.props.getTeamData({ page, search, includeEmail })
  }

  render() {
    const {
      openDialog,
      openAddUser,
      openEditUser,
      rolesSelected,
      dialogTitle,
      userSelected,
      openAlertBlock,
      openAlertDelete,
      editUserIsEnabled,
      blockPlatformUserIsEnabled,
      registerPlatformUserIsEnabled,
      externalUsersManager,
      reactiveUserAccountsIsEnabled,
      deletePlatformUserIsEnabled,
      modifyRolesIsEnabled,
      enrollUsersInCompanyIsEnabled,
      openAlertConfirmUser,
      messageAvailableUsers,
      openDialogError,
      messageError,
      openConfirmEditUser,
      message,
    } = this.state;

    const { users, fetchingUsers, companies, roles, currentPage, totalPages } = this.props;
    return (
      <React.Fragment>
        <Grid container className='actionUsers' justifyContent="flex-end">
          <Grid item md={8} xs={12} sm={8}>
            <Pagination
              size="medium"
              count={totalPages}
              disabled={totalPages === 1}
              color="primary"
              onChange={this.handlePagination}
              page={currentPage}
            />
          </Grid>
          <Grid className='text-right' item md={4} xs={12} sm={4}>
            <Button
              variant='contained'
              color='primary'
              disabled={!(registerPlatformUserIsEnabled | externalUsersManager)}
              onClick={() => this.setState({
                userSelected: null,
                openDialog: true,
                openAddUser: true,
                dialogTitle: 'Añadir Usuario',
                typeForm: 'create',
              })}
            >
              + AÑADIR USUARIO
            </Button>
          </Grid>

        </Grid>

        <Grid container>
          {!fetchingUsers &&
            users &&
            users.length > 0 &&
            users.map((user) => {

              const { id, externalProvider, blocked, deleted } = user;

              let menuItem = [];

              menuItem.push({
                label: 'Editar datos de usuario',
                value: 'edit',
                disabled: externalProvider
                  ? externalUsersManager ? false : !editUserIsEnabled
                  : !editUserIsEnabled,
              });

              !externalProvider && menuItem.push({
                label: `${blocked ? 'Desbloquear' : 'Bloquear'} usuario`,
                value: 'disable',
                disabled: externalProvider
                  ? externalUsersManager ? false : blocked ? !reactiveUserAccountsIsEnabled : !blockPlatformUserIsEnabled
                  : blocked ? !reactiveUserAccountsIsEnabled : !blockPlatformUserIsEnabled
              });

              if (!deleted) {
                menuItem.push({
                  label: 'Borrar usuario',
                  value: 'delete',
                  disabled: externalProvider
                    ? externalUsersManager ? false : !deletePlatformUserIsEnabled
                    : !deletePlatformUserIsEnabled,
                });
              }

              if (!user.deleted) {
                return (
                  <CardUsers
                    key={id}
                    user={user}
                    allRoles={roles}
                    menuItems={menuItem}
                    handleSelectOption={(data) => this.selectUserOption(data, user)}
                    onSubmitRoles={(evt) => this.handleSaveRoles(evt, user)}
                    rolesBtnEnabled={modifyRolesIsEnabled && !user.externalProvider}
                  />
                );
              }
            })}

          {!fetchingUsers && users && users.length === 0 && (
            <Grid container style={{ justifyContent: 'center', width: '100%' }}>
              <AlertInfo severity='info'>No hay información!</AlertInfo>
            </Grid>
          )}

          {fetchingUsers && (
            <Grid container style={{ justifyContent: 'center', width: '100%' }}>
              <AlertInfo severity='info'>Cargando usuarios...</AlertInfo>
            </Grid>
          )}
        </Grid>

        <Dialog open={openDialog}>
          <FormUsers
            title={dialogTitle}
            externalUsersManager={openAddUser
              ? externalUsersManager && !registerPlatformUserIsEnabled
              : externalUsersManager && !editUserIsEnabled}
            initialValuesRoles={rolesSelected}
            submitActions={(formData) => this.confirmSubmit(formData)}
            roles={roles}
            companies={companies}
            onCancel={() =>
              this.setState({ openDialog: false, businessSelect: null, openAddUser: false })
            }
            onErrorAvailableUsers={() => this.setState({
              openDialog: false,
              businessSelect: null,
              openAddUser: false,
              openDialogError: true,
              messageError: "Error de comunicacion con el servidor: availableUsers"
            })}
            usersAvailable={(usersAvailable) => this.setState({ usersAvailable })}
            userSelected={userSelected}
          />
        </Dialog>

        <AlertConfirm
          open={openAlertBlock}
          onCancel={() => this.setState({ openAlertBlock: false })}
          onConfirm={() => this.handleBlockUser()}
          textContent={`El usuario será ${userSelected
            ? userSelected.blocked
              ? 'desbloqueado'
              : 'bloqueado'
            : ''
            }. ¿Deseas continuar?`}
        />

        <AlertConfirm
          open={openAlertDelete}
          onCancel={() => this.setState({ openAlertDelete: false })}
          onConfirm={() => this.handleDeleteUser()}
          textContent='El usuario será eliminado. ¿Deseas continuar?'
        />

        <AlertConfirm
          open={openAlertConfirmUser}
          onCancel={() => this.setState({ openAlertConfirmUser: false })}
          onConfirm={() => this.submitSave()}
          textContent={messageAvailableUsers}
        />

        <AlertConfirm
          open={openConfirmEditUser}
          onCancel={() => this.setState({ openConfirmEditUser: false })}
          onConfirm={() => this.confirmEditUser()}
          textContent={message}
        />

        <Alert
          open={openDialogError}
          title={messageError}
          type="error"
          onConfirm={() => this.setState({ openDialogError: false, messageError: "" })}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alert: state.alertReducer,
    users: state.userReducer.users,
    roles: state.userReducer.roles,
    companies: state.userReducer.companies,
    fetchingUsers: state.userReducer.fetchingUsers,
    currentUser: state.authReducer.currentUser,
    currentPage: state.userReducer.currentPage,
    totalPages: state.userReducer.totalPages,
    includeEmail: state.userReducer.includeEmail,
    search: state.userReducer.search,
  };
};

const mapDispatchToProps = {
  closeAlert: alertActions.close,
  createUser: usersActions.createNewUser,
  editUser: usersActions.editUser,
  blockUser: usersActions.blockUser,
  deleteUser: usersActions.deleteUser,
  assignRoles: usersActions.assignRoles,
  getTeamData: usersActions.findAllv2,
  setPage: usersActions.setCurrentPageUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
