import { useCallback, useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

export const useRenderCanvas = (url) => {
  // eslint-disable-next-line no-undef
  const canvasRef = useRef(null);
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

  const [pdfRef, setPdfRef] = useState();
  const [canvasHeight, setCanvasHeight] = useState();
  const [canvasWidth, setCanvasWidth] = useState();
  const [viewport, setViewport] = useState();
  const [scale, setScale] = useState();

  const setCanvasSize = async (pageNum, pdf = pdfRef) => {
    if (pdf) {
      const page = await pdf.getPage(pageNum);
      let viewport = page.getViewport({ scale: 1 });
      // 64 is the top bar heigth, 0.95: 95% of the available space.
      const actualScale = ((window.innerHeight - 64) / viewport.height) * 0.95;

      viewport = page.getViewport({ scale: actualScale });
      const canvas = canvasRef.current;
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      setCanvasWidth(canvas.width);
      setCanvasHeight(canvas.height);
      setViewport(viewport);
      setScale(actualScale);
    }
  };

  const renderPage = useCallback(
    async (pageNum, pdf = pdfRef) => {
      if (pdf) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 2 });
        // 64 is the top bar heigth, 0.95: 95% of the available space.
        const actualScale = ((window.innerHeight - 64) / viewport.height) * 0.95;

        const canvas = canvasRef.current;
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        canvas.style.height = viewport.height * actualScale + 'px';
        canvas.style.width = viewport.width * actualScale + 'px';

        const renderContext = {
          canvasContext: canvas.getContext('2d'),
          viewport: viewport
        };

        const pageRendering = page.render(renderContext);
        const completeCallback = pageRendering._internalRenderTask.callback;
        pageRendering._internalRenderTask.callback = function (error) {
          completeCallback.call(this, error);
        };
      }
    },
    [pdfRef]
  );

  useEffect(() => {
    const loadingTask = pdfjsLib.getDocument(url);
    loadingTask.promise.then(
      (loadedPdf) => {
        setPdfRef(loadedPdf);
      },
      function (reason) {
        console.error(reason);
      }
    );
  }, [url]);

  return { canvasRef, renderPage, pdfRef, canvasHeight, canvasWidth, setCanvasSize, viewport, scale };
};
