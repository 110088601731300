import { AuthService } from '../services';
import { authConstants } from '../constants';
import { alertActions } from './'
import { getUserPermissionsGroup } from "../utils/can-i";

const authService = new AuthService()

function login(username, password, client) {
  return dispatch => {
    authService.login(username, password, client).then(response => {
      const {
        access_token,
        refresh_token,
        clientId,
        clientKey,
        userId,
        name
      } = response.data;

      sessionStorage.setItem('access_token', access_token)
      sessionStorage.setItem('refresh_token', refresh_token)
      sessionStorage.setItem('userId', userId)
      sessionStorage.setItem('clientId', clientId)
      sessionStorage.setItem('clientKey', clientKey)
      localStorage.setItem('clientKey', clientKey)
      sessionStorage.setItem('sgc_completename', name)


      //   getUserPermissionsGroup().then((userPermissionsGroup) => {
      //     sessionStorage.setItem('sgc_permissions', JSON.stringify(userPermissionsGroup))
      //     dispatch({ type: authConstants.LOGIN_SUCCESS, payload: { userId } })
      //   }
      // ).catch(() => {
      //   dispatch({ type: authConstants.LOGIN_SUCCESS, payload: { userId } })
      // });

      window.location.reload(true)
    }

    ).catch(error => {
      dispatch({
        type: authConstants.LOGIN_FAILURE
      })

      if (error.response && error.response.status === 400) {
        if (error.response.data.error_description === 'Bad credentials') {
          dispatch(alertActions.error('Usuario o contraseña incorrectos.'))
        } else if (error.response.data.error_description === "User account is locked") {
          dispatch(alertActions.error('La cuenta ha sido bloqueada.'))
        }
      } else {
        if (error.response.status === 401) {
          dispatch(alertActions.error('Debes crear tu cuenta de usuario para poder ingresar al sistema'))
        } else if (error.response.status === 500) {
          dispatch(alertActions.error('Usuario o contraseña incorrectos.'))
        } else {
          dispatch(alertActions.error('Ocurrió un error inesperado, intenta más tarde.'))
        }
      }
    })
  }
}

function signUp(user) {
  return dispatch => {
    authService.signUp(user)
      .then(() => {
        dispatch({
          type: authConstants.SIGNUP_SUCCESS
        })
        dispatch(alertActions.success('Activación exitosa'))
      })
      .catch(error => {
        dispatch({
          type: authConstants.SIGNUP_FAILURE
        })
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function activeSession(keep_active_session) {
  return dispatch => {
    dispatch({ type: authConstants.ACTIVE_SESSION, payload: { keep_active_session } })
  }
}

function logout() {
  return dispatch => {
    sessionStorage.clear()
    dispatch({
      type: authConstants.LOGOUT
    })
  }
}

function sendEmailPassword(email, clientId) {
  return dispatch => {
    authService.sendEmailPassword(email, clientId)
      .then(response => {
        dispatch({
          type: authConstants.SENT_EMAIL_PAWSSWORD
        })
        dispatch(alertActions.success('Email enviado correctamente. Revise su bandeja con las instrucciones para restablecer su contraseña.'))
      })
      .catch(error => {
        dispatch({
          type: authConstants.SEND_EMAIL_PASSWORD_FAILURE
        })
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function restorePassword(uuid) {
  return dispatch => {
    authService.restorePassword(uuid)
      .then(response => {
        dispatch({
          type: authConstants.RESTORE_PASSWORD_SUCCESS
        })
        dispatch(alertActions.success('Constraseña restablecida correctamente.'))
      })
      .catch(error => {
        dispatch(alertActions.error(error.response.data))
      })
  }
}

function refreshToken() {
  return async (dispatch) => {
    await authService.refreshToken(sessionStorage.getItem('refresh_token'))
      .then((response) => {
        dispatch({
          type: authConstants.REFRESH_TOKEN_SUCESS
        })
      })
      .catch(error => {
        dispatch({ type: authConstants.REFRESH_TOKEN_FAILURE })
      })
  }
}

const userLoggedOut = () => ({ type: authConstants.USER_LOGGED_OUT })

export const authActions = {
  login,
  logout,
  signUp,
  activeSession,
  sendEmailPassword,
  refreshToken,
  restorePassword,
  userLoggedOut
}