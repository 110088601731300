import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const DescriptionBasic = ({ items }) => {
  return (
    <Grid container spacing={1}>
      {
        items.map(item => (
          <Grid container item key={item.label}>
            <Grid item xs sm={4} md={3} lg={2}>
              <Typography color="primary">
                {item.label}
              </Typography>
            </Grid>
            <Grid item xs sm={8} md={9} lg={10}>
              {item.value}
            </Grid>
          </Grid>
        ))
      }
    </Grid>
  )
}
