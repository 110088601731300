import React, { useEffect } from 'react';
import { DescriptionBasic } from '../../components/description-basic/DescriptionBasic';
import { useHistory } from "react-router-dom";
import { getCurrentUser } from '../../core/helpers';

const GeneralData = ({ data }) => {
  const history = useHistory();

  useEffect(() => {
    const { hasBusinessMgmt } = getCurrentUser();

    if (!hasBusinessMgmt) {
      history.push("/dashboard/home")
    }
  }, [])

  return (
    <DescriptionBasic
      items={data}
    />
  )
}

export default GeneralData