import React, { Component, useEffect } from "react";
import PropTypes from 'prop-types';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  withStyles
} from "@material-ui/core";

import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Link } from "react-router-dom";
import { uuid } from "axeleratum-sgc-frontend-library";
import { getUserPermissionsGroup } from "../../core/utils/can-i";
import { useState } from "react";
import { getCurrentUser } from "../../core/helpers";
import { tokenExpired } from "../../core/utils";
import { authActions } from "../../core/actions";
import { useDispatch, useSelector } from "react-redux";

const appStyles = (theme) => ({
  listItem: {
    borderRadius: "6px",
    backgroundColor: theme.itemMenu.primary.main,
    "&:hover": {
      background: theme.itemMenu.primary.main,
    },
    color: theme.palette.background.paper,
    marginBottom: theme.spacing(4),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuItemIcon: {
    color: theme.palette.background.paper,
  },
  itemLogout: {
    marginTop: 'auto',
  },
  menuItemIconSelected: {
    color: theme.palette.primary.main,
  },
  listItemSelected: {
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.background.paper,
    },
    color: theme.palette.primary.main
  },
  selected: {
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      background: theme.palette.background.paper,
    },
    color: theme.palette.primary.main
  },
  gutters: {
    paddingLeft: '12px'
  },
  itemIconKasia: {
    padding: 'unset',
    marginTop: 'auto'
  },
  iconKasia: {
    fontSize: '48px',
  }
});

const AppMenuItems = (props) => {

  const { itemSelected, onItemSelected, expanded, classes, menuItems } = props;
  const itemSelectedClass = `${classes.listItem} ${classes.listItemSelected}`;
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const dispatch = useDispatch();

  const [valid, setValid] = useState(false);
  const [menus, setMenus] = useState([]);

  const renderMenus = async () => {

    try {
      const userPermissionsGroup = await getUserPermissionsGroup();
      const menus = [];
      menuItems.forEach(item => {
        if (item.enabled
          || userPermissionsGroup.some(group =>
            (group.name === "Administración del Sistema" || item.groups.some(item2 => item2 === group.name))
            && group.permissions.length > 0)
        ) {
          menus.push(item);
        }
      });
      const { showIdMgmtButton, hasBusinessMgmt } = getCurrentUser();

      const filteredMenus = menus.filter(el => {
        if (el.name === "idmgmt") {
          return showIdMgmtButton
        }
        if (el.name === "business_mgmt") {
          return hasBusinessMgmt
        }
        return true
      });

      setValid(true)
      setMenus(filteredMenus);
    } catch (error) {
      console.error(error);
      setValid(true);
      setMenus([])
    }
  }
  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    renderMenus();
    // eslint-disable-next-line
  }, [])


  return (
    <List className={classes.list}>
      {valid && menus.map(item => {
        return (
          <Tooltip key={uuid()} title={item.text}>
            {
              item.name === "idmgmt" ? (
                <ListItem
                  className={`${itemSelected === item.name
                    ? itemSelectedClass
                    : classes.listItem
                    }`}
                  classes={{ gutters: classes.gutters }}
                  button
                  key={item.name}
                  onClick={() => onItemSelected(item.name)}
                >
                  {!!item.icon && (
                    <ListItemIcon
                      className={`${itemSelected === item.name
                        ? classes.menuItemIconSelected
                        : classes.menuItemIcon
                        }`}
                    >
                      <item.icon />
                    </ListItemIcon>
                  )}

                  {expanded && <ListItemText primary={item.text} />}
                </ListItem>
              ) : (
                <ListItem
                  className={`${itemSelected === item.name
                    ? itemSelectedClass
                    : classes.listItem
                    }`}
                  classes={{ gutters: classes.gutters }}
                  button
                  component={Link}
                  to={`/dashboard${item.link}`}
                  key={item.name}
                  onClick={() => onItemSelected(item.name)}
                >
                  {!!item.icon && (
                    <ListItemIcon
                      className={`${itemSelected === item.name
                        ? classes.menuItemIconSelected
                        : classes.menuItemIcon
                        }`}
                    >
                      <item.icon />
                    </ListItemIcon>
                  )}

                  {expanded && <ListItemText primary={item.text} />}
                </ListItem>
              )
            }
          </Tooltip>
        );
      })}
      <Tooltip title={'Cerrar sesión'}>
        <ListItem
          className={`${itemSelected === "logout"
            ? itemSelectedClass
            : classes.listItem
            } ${classes.itemLogout}`}
          classes={{ gutters: classes.gutters }}
          button
          onClick={() => onItemSelected('logout')}
        >
          <ListItemIcon
            className={`${itemSelected === "logout"
              ? classes.menuItemIconSelected
              : classes.menuItemIcon
              }`}
          >
            <PowerSettingsNewIcon />
          </ListItemIcon>

          {expanded && <ListItemText primary={"Cerrar sesión"} />}
        </ListItem>
      </Tooltip>
    </List>
  );

};

AppMenuItems.propTypes = {
  menuItems: PropTypes.array.isRequired,
  itemSelected: PropTypes.string
}

export default withStyles(appStyles)(AppMenuItems);
