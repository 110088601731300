import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Grid,
  makeStyles,
  Card,
  CardContent,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Header from '../../components/dashboard/header';
import { Link } from 'react-router-dom';
import GeneralData from './GeneralData';
import { useHistory } from "react-router-dom";
import { getCurrentUser } from '../../core/helpers';
import { tokenExpired } from '../../core/utils';
import { authActions } from '../../core/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessById } from '../../core/http/functionRequests/business-management';
import { useParams } from 'react-router-dom/cjs/react-router-dom';


const useStyles = makeStyles((theme) => ({
  link: {
    '&:hover': { textDecoration: "none" }
  },
  container: {
    padding: "15px 15px 15px 15px !important",
  },
  containerBread: {
    marginBottom: "20px"
  },
  infoCompany: {
    paddingLeft: '15px',
  },
  card: {
    width: "100%",
    backgroundColor: "#5C7BF0",
  },
  cardName: {
    fontWeight: 'bold !important',
    color: "white !important",
    textAlign: 'center',
    padding: "16px !important"
  }
}));

const BusinessDashboard = () => {

  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [business, setBusiness] = useState(null)
  const [loading, setLoading] = useState(false)

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const requestBusinessById = async () => {
    try {
      setLoading(true)
      const { businessId } = params;
      const result = await getBusinessById(businessId)
      setBusiness(result);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    const { hasBusinessMgmt } = getCurrentUser();

    if (!hasBusinessMgmt) {
      history.push("/dashboard/home")
    }

    requestBusinessById()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header
        titleLeft='Tablero'
        titleRight={business ? business.name : ''}
        actions={[
          <IconButton variant='outlined'>
            <NotificationsIcon color='primary' />
          </IconButton>,
          <IconButton variant='outlined'>
            <EventIcon color='primary' />
          </IconButton>,
        ]}
      />

      <Grid
        container
        spacing={3}
        className={classes.container}
      >
        {
          !loading && business
            ? (
              <Grid container item>
                <Grid item xs={12} className={classes.infoCompany}>
                  <GeneralData data={business.fields ?? []} />
                </Grid>
              </Grid>
            ) : <></>
        }

        <Grid container item className={classes.container} spacing={3} xs={12} xl={6}>
          {
            !loading && business
              ? business.charts?.map(chart => (
                <Grid item key={chart.name + Date.now()} xs sm={4} md={3} lg={3}>
                  <Link
                    className={classes.link}
                    to={`/dashboard/business-management/${chart.routeName}/${chart.id}`}
                  >
                    <Card className={classes.card} >
                      <CardContent className={classes.cardName}>
                        {chart.name.toUpperCase()}
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              )) : <></>
          }
        </Grid>
      </Grid>


    </>
  )
}

export default BusinessDashboard